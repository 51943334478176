<template>
	<div>04</div>
</template>

<script>
export default {
	name: "Day04"
};
</script>

<style scoped></style>
